import React from 'react';
import styled from 'styled-components';
import NewsCarousel from './newsCarousel';
import withHighlightedNews from '../data/highlightedNews';
import withMenTeamNews from '../data/menTeamNews';
import withWomenTeamNews from '../data/womenTeamNews';
import withLatestNews from '../data/latestNews';
import withCouncilNews from '../data/councilNews';

export {
  withHighlightedNews,
  withMenTeamNews,
  withWomenTeamNews,
  withLatestNews,
  withCouncilNews,
};

const Container = styled.div`
  width: 100%;
`;

export function NewsWidget({ news }) {
  return (
    <Container>
      {' '}
      <NewsCarousel news={news} />{' '}
    </Container>
  );
}

const Memoized = React.memo(NewsWidget);

function Widget({ HOC }) {
  return HOC(Memoized);
}

export default React.memo(Widget);
