import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import styled from 'styled-components';
import { ImageOverlay } from 'ground-web/components/v1/GridGallery';

const InvisibleButton = styled.button`
  background: transparent;
  border: none;
  margin-bottom: 1rem;
`;

const Figure = styled.figure`
  display: flex;
  height: 30rem;
  justify-content: center;
  align-items: center;
  background-image: url('${(props) => props.backgroundImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &.swiper-slide {
    height: 30rem;
    @media (max-width: ${(props) => props.theme.dimensions.mobile.med}) {
      height: 15rem;
    }
  }
  img {
    visibility: hidden;
    height: inherit;
  }
`;

const Thumbnail = styled(Figure)`
  background-size: cover;
  &.swiper-slide {
    height: 10rem;
    @media (max-width: ${(props) => props.theme.dimensions.mobile.med}) {
      height: 5rem;
    }
    width: auto;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  div {
    height: inherit;
  }
  .swiper-button-next,
  .swiper-button-prev {
    height: 100px;
    width: 100px;
    font-weight: 700;
    transform: translateY(-50%);
    margin-top: 0;
    background: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    color: ${({ theme }) => theme.colors.white};
  }
  @media (max-width: ${(props) => props.theme.dimensions.mobile.med}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
`;

export default function ThumbGallery({ gallery }) {
  const hasNoGallery = !gallery;
  if (hasNoGallery) {
    return null;
  }

  const [openImage, setOpenImage] = useState({ open: false, image: null });
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true,
  };
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  const {
    galleryData: { imagens },
  } = gallery;
  const filteredImages = imagens.filter((img) => img.imagem);
  return (
    <Container>
      <Swiper {...gallerySwiperParams}>
        {filteredImages.map((img) => (
          <InvisibleButton
            type="button"
            key={`gallery-${img.imagem.id}`}
            onClick={() =>
              setOpenImage({ open: true, image: img.imagem.sourceUrl })
            }
          >
            <Figure backgroundImage={img.imagem.sourceUrl}>
              <img alt="Imagem do atleta" />
            </Figure>
          </InvisibleButton>
        ))}
      </Swiper>
      <Swiper {...thumbnailSwiperParams}>
        {filteredImages.map((img) => (
          <Thumbnail
            key={`thumbnail-${img.imagem.id}`}
            backgroundImage={img.imagem.sourceUrl}
          >
            <img alt="Imagem do atleta" />
          </Thumbnail>
        ))}
      </Swiper>
      <ImageOverlay openImage={openImage} setOpenImage={setOpenImage} />
    </Container>
  );
}
