import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ReactHtmlParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { decodeHtml, sortPlayers } from '../helpers';
import SEO from '../components/seo';
import Styles, { Section } from './styles';
import ConditionalRenderer from '../components/conditionalRenderer';
import LinkDiv from '../components/LinkDiv';
import Card from '../components/PlayersCard';
import ResponsiveCard, {
  Content as CardContent,
} from 'ground-web/components/v1/ResponsiveCard';
import ThumbGallery from '../components/thumb-gallery';
import PlayerNewsWidget, {
  withMenTeamNews,
  withWomenTeamNews,
} from '../components/news-widget';
import SeeMore from '../components/see-more';

const LinkWrapper = styled(Section)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  a:first-child {
    text-align: left;
    p {
      margin-left: 1rem;
    }
  }
  a:last-child {
    text-align: right;
    p {
      margin-right: 1rem;
    }
  }
  a {
    color: ${(props) => props.theme.colors.text_on_primary};
    background: ${(props) => props.theme.colors.primary};
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 10px;
    line-height: 0;
    min-width: 9rem;
    max-width: 11rem;
    display: flex;
    .max-width {
      width: 100%;
    }
    div {
      margin: auto;
      p {
        overflow: hidden;
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2; // max nb lines to show
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0.5rem 0;
      }
    }
  }
  @media (max-width: 450px) {
    a {
      margin: 0.1rem 0;
    }
  }
`;

const Article = styled.article`
  margin: 5rem auto;
`;

const Content = styled.div`
  max-width: ${({ theme }) => theme.articles.header.max_width};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 2rem;
  overflow-wrap: break-word;
  @media (min-width: 64rem) {
    max-width: ${({ theme }) => theme.articles.content.max_width};
  }

  & > a:not(:last-child) {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ResponsiveCardBorderless = styled(ResponsiveCard)`
  border: none;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  & > h2 {
    font-family: 'Bebas Neue';
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
  }

  small {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1rem;
    opacity: 0.7;
  }
`;

//REFACTOR: Use Edges to get the next and prev page
function getPrevAndNextPlayers(players, title) {
  return players.reduce((accumulator, current, index, originalArray) => {
    if (Array.isArray(accumulator)) {
      if (accumulator.length === 1) {
        accumulator.push(current);
      }
      return accumulator;
    }
    if (current.title === title) {
      const size = originalArray.length;
      if (index === 0) {
        if (size === 1) {
          return [];
        }
        return [originalArray[size - 1]];
      }
      if (size === index + 1) {
        return [accumulator, originalArray[0]];
      }
      return [accumulator];
    }
    return current;
  }, {});
}

const NewsSection = ({ team }) => {
  const teamLowered = team.toLowerCase();
  const isWomenTeam = teamLowered === 'futebol feminino';
  const hocTeamNews = isWomenTeam ? withWomenTeamNews : withMenTeamNews;
  return (
    <Section>
      <SectionHeader>
        <h2>Notícias</h2>
        <SeeMore to={`/noticias-${teamLowered.replace(' ', '-')}`} />
      </SectionHeader>
      <PlayerNewsWidget HOC={hocTeamNews} />
    </Section>
  );
};

export default function Players(props) {
  const {
    pageContext: { player, team },
  } = props;
  const {
    title,
    playerData: { trajetoria, elenco },
    galleryData: { gallery },
  } = player;

  const teamPages = [
    { slug: 'futebol/futebol-masculino', title: 'Futebol Masculino' },
    { slug: 'futebol/futebol-feminino', title: 'Futebol Feminino' },
    // { slug: 'futebol/interb', title: 'Inter B' },
    { slug: 'futebol/juniores', title: 'Júnior' },
    { slug: 'futebol/juvenil', title: 'Juvenil' },
  ];
  const otherPlayers = getPrevAndNextPlayers(
    sortPlayers(team.players),
    player.title,
  );
  const decodedTitle = decodeHtml(title);
  return (
    <>
      <SEO title={decodedTitle} />
      <Styles />
      <Article>
        <Content>
          <LinkDiv list={teamPages} />
          <Card player={player} />
          <LinkWrapper>
            <ConditionalRenderer
              condition={otherPlayers[0]}
              renderOnSuccess={() => (
                <Link to={`/futebol/${otherPlayers[0]?.slug}`}>
                  <div>
                    <FontAwesomeIcon icon={faAngleLeft} width="0" />
                  </div>
                  <div className="max-width">
                    <p>{decodeHtml(otherPlayers[0]?.title)}</p>
                  </div>
                </Link>
              )}
            />
            <ConditionalRenderer
              condition={otherPlayers[1]}
              renderOnSuccess={() => (
                <Link to={`/futebol/${otherPlayers[1]?.slug}`}>
                  <div className="max-width">
                    <p>{decodeHtml(otherPlayers[1]?.title)}</p>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faAngleRight} width="0" />
                  </div>
                </Link>
              )}
            />
          </LinkWrapper>
          <ConditionalRenderer
            condition={typeof trajetoria === 'string'}
            renderOnSuccess={() => (
              <Section>
                <SectionHeader>
                  <h2>Trajetória</h2>
                </SectionHeader>
                <ResponsiveCardBorderless>
                  <CardContent>
                    {ReactHtmlParser(trajetoria ? trajetoria : '')}
                  </CardContent>
                </ResponsiveCardBorderless>
              </Section>
            )}
          />
          <ConditionalRenderer
            condition={gallery}
            renderOnSuccess={() => (
              <Section>
                <SectionHeader>
                  <h2>Imagens</h2>
                </SectionHeader>
                <ThumbGallery
                  gallery={Array.isArray(gallery) ? gallery[0] : []}
                />
              </Section>
            )}
          />
          <NewsSection team={elenco} />
        </Content>
      </Article>
    </>
  );
}
