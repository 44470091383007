import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { parseData } from '../helpers/news';

export default function withMenTeamNews(Component) {
  const query = graphql`
    query GET_MEN_HIGHLIGHTS {
      wpgraphql {
        posts(
          first: 20
          where: {
            categoryName: "futebol-masculino"
            orderby: { field: DATE, order: DESC }
          }
        ) {
          nodes {
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            slug
            status
            id
            content
            date
            modified
            excerpt
            postData {
              hidden
              highlight
              redirectLink
              exclusive
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            tags {
              nodes {
                slug
                name
                id
              }
            }
          }
        }
      }
    }
  `;

  const render = (data) => {
    const {
      wpgraphql: {
        posts: { nodes },
      },
    } = data;
    const news = parseData(
      nodes.filter((post) => post.postData.hidden !== true),
    );

    return <Component news={news} />;
  };

  return <StaticQuery query={query} render={render} />;
}
