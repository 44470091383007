import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Container = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2em;
  text-transform: uppercase;

  svg:last-child {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary_dark};
  }
`;

export default function SeeMore(props) {
  const { to } = props;

  return (
    <Link to={to}>
      <Container>
        Ver mais
        <FontAwesomeIcon icon={faArrowRight} width="0" />
      </Container>
    </Link>
  );
}
