import styled, { createGlobalStyle } from 'styled-components';

export const Content = styled.div`
  padding: 0 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 2rem;
  overflow-wrap: break-word;

  @media (min-width: ${({ theme }) => theme.width.mobile}) {
    padding: 0 1rem;
  }
`;

export const Header = styled.section`
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.articles.header.max_width};

  h1 {
    font-weight: 700;

    @media (min-width: 64rem) {
      font-size: 2.25rem;
      padding: 0;
    }
  }
`;

export const Title = styled.h2`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.color.primary_dark};
  text-transform: uppercase;
  font-family: 'Bebas Neue', cursive;

  @media (max-width: ${({ theme }) => theme.width.mobile}) {
    font-size: 1.5rem;
  }
`;

export const Section = styled.section`
  padding: 0 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  overflow-wrap: break-word;
  max-width: ${({ theme }) => theme.articles.header.max_width};
  @media (min-width: 64rem) {
    margin-left: auto;
    margin-right: auto;
    max-width: ${({ theme }) => theme.articles.header.max_width};
    padding: 0 1rem;
  }
  h3 {
    color: ${({ theme }) => theme.color.primary};
    font-size: 1.6rem;
  }
`;

const Styles = createGlobalStyle`
.wp-block-quote {
  border-left: 2px solid ${({ theme }) => theme.color.primary};
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1em;
  margin: 32px 0;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
  font-style: italic;
}

.wp-block-gallery {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.wp-block-gallery .blocks-gallery-item {
  flex: 1 1 150px;
  margin: 5px;
  width: 250px;
  overflow: hidden;
  transition: all 0.2s ease-in;
}

.wp-block-embed-youtube {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  width: 100%;
}

.wp-block-embed-youtube iframe,
.wp-block-embed-youtube embed,
.wp-block-embed-youtube object {
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wp-block-cover {
  position: relative;
  min-height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1.2rem;
}

.wp-block-cover__inner-container {
  width: calc(100% - 70px);
  z-index: 1;
  color: #f8f9f9;
}

.wp-block-cover.has-background-dim::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: inherit;
  opacity: 0.5;
  z-index: 1;
}

.wp-block-cover__inner-container .has-large-font-size {
  font-size: 1.6875em;
}

.wp-block-file {
  display: flex;
}

.wp-block-file .wp-block-file__button {
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.color.text_on_primary};
  text-transform: uppercase;
  border-radius: 2px;
  padding: 0 1rem;
  min-height: 36px;
  min-width: 64px;
  display: flex;
  justify-content: center;
  align-content: center;
  white-space: nowrap;
  margin-left: 1rem;
  /* Temporarily hide the download button */
  visibility: hidden;
}

.wp-block-image {
  width: 100%;
  
  img {
    margin: 0;
  }
  
  figcaption {
    font-size: 0.8rem;
    opacity: 0.7;
  }
}

`;

export default Styles;
