import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { parseData } from '../helpers/news';

export default function withLatestNews(Component) {
  const query = graphql`
    query GET_LATEST {
      wpgraphql {
        posts(first: 100, where: { orderby: { field: DATE, order: DESC } }) {
          nodes {
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            slug
            status
            id
            content
            date
            modified
            excerpt
            postData {
              hidden
              highlight
              redirectLink
              exclusive
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            tags {
              nodes {
                slug
                name
                id
              }
            }
          }
        }
      }
    }
  `;

  const render = (data) => {
    const {
      wpgraphql: {
        posts: { nodes },
      },
    } = data;
    const filteredNews = nodes.filter((item) => {
      if (item.postData.hidden === true) {
        return false;
      }

      const categories = item.categories.nodes.reduce(
        (acc, el) => [...acc, el.slug],
        [],
      );

      return categories.indexOf('destaques') === -1;
    });
    const news = parseData(filteredNews);

    return <Component news={news.slice(0, 20)} />;
  };

  return <StaticQuery query={query} render={render} />;
}
