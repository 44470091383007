import React from 'react';
import styled from 'styled-components';
import SocialLinks from 'ground-web/components/v1/SocialLinks';
import ResponsiveCard, {
  Cover,
  Content as CardContent,
} from 'ground-web/components/v1/ResponsiveCard';
import { decodeHtml, convertPositionNounGender } from '../helpers';
import ConditionalRenderer from './conditionalRenderer';

const Card = styled(ResponsiveCard)`
  min-height: 28rem;
  border: none;
  margin-bottom: 2.5rem;
  article {
    @media (max-width: 450px) {
      padding: 0;
    }
  }
`;

const OverImage = styled.p`
  margin: 0.625rem;
  position: absolute;
  @media (max-width: 508px) {
    margin: 0;
    position: initial;
  }
  z-index: 1;
  top: 0;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.text_on_primary};
  font-weight: bold;
  font-size: 0.9rem;
`;

const ShirtP = styled(OverImage)`
  left: 0;
  @media (max-width: 508px) {
    position: absolute;
    top: 2.5rem;
    left: 0.5rem;
  }
  border-radius: 50%;
  line-height: 0.7rem;
  padding: 0.65rem;
  svg {
    margin: 0 0.2rem;
  }
`;

const PositionP = styled(ShirtP)`
  height: 2.1rem;
  width: 2.2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageCover = styled.div`
  position: relative;
  @media (max-width: 676px) {
    height: 34rem;
    margin-bottom: 1rem;
  }
  figure {
    background-size: contain;
    background-position: initial;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  margin-bottom: 3rem;
  p {
    width: 100%;
    margin: 0;
    font-size: 1rem;
  }
  h2 {
    width: 100%;
    margin: 0;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    text-transform: uppercase;
  }
  p:last-child {
    flex-grow: 1;
    width: 100%;
    text-align: right;
    font-size: 2rem;
    svg:last-child {
      color: ${(props) => props.theme.color.primary};
    }
  }
  @media (max-width: 800px) {
    p {
      font-size: 1rem;
    }
  }
  button {
    background: ${(props) => props.theme.color.primary};
    transition: background 0.25s ease-in-out;
    &:hover {
      background: ${(props) => props.theme.color.primary_dark};
    }
  }
`;

const ExternalLink = styled.a.attrs((props) => ({
  href: props.to,
}))`
  flex-basis: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.text_on_primary};
  background: ${(props) => props.theme.color.primary};
  transition: background 0.25s ease-in-out;
  border-radius: 1rem;
  font-weight: 700;
  &:hover {
    background: ${(props) => props.theme.color.primary_dark};
  }
`;

const CardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  p {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem;
    margin-bottom: 3rem;
    padding: 0 0.5rem;
    border-left: 2px ${(props) => props.theme.color.primary} solid;
    span {
      font-weight: normal;
      font-size: smaller;
      line-height: 1rem;
    }
  }
  p:nth-child(-n + 2) {
    span {
      text-transform: uppercase;
    }
  }
`;

export default function PlayerCard(props) {
  const { player } = props;
  const { title, playerData, position: posicao } = player;
  const {
    numeroDaCamiseta,
    numeroDeGols,
    numeroDePartidas,
    dataDeNascimento,
    elenco,
    camiseta,
    dataDeEstreia,
    altura,
    foto: { sourceUrl },
    localDeNascimento,
    redesSociais,
    nome,
  } = playerData;
  const { cidade, pais } = localDeNascimento || {};
  return (
    <Card>
      <ImageCover>
        {/* <Group>{elenco}</Group> */}
        <ConditionalRenderer
          condition={numeroDaCamiseta}
          renderOnSuccess={() => <PositionP>{numeroDaCamiseta}</PositionP>}
        />
        <Cover src={sourceUrl} alt={title} />
      </ImageCover>
      <CardContent>
        <CardHeader>
          <p>{convertPositionNounGender(posicao.label, elenco)}</p>
          <h2>{decodeHtml(title)}</h2>
          <p>{nome}</p>
          <SocialLinks networks={redesSociais} size="small" />
        </CardHeader>
        <CardBody>
          <ConditionalRenderer
            condition={dataDeNascimento}
            renderOnSuccess={() => (
              <p>
                {dataDeNascimento}
                <span>Data de Nascimento</span>
              </p>
            )}
          />
          <ConditionalRenderer
            condition={cidade}
            renderOnSuccess={() => (
              <p>
                {`${cidade}/${pais}`}
                <span>Local de Nascimento</span>
              </p>
            )}
          />
          <ConditionalRenderer
            condition={dataDeEstreia}
            renderOnSuccess={() => (
              <p>
                {dataDeEstreia}
                <span>Estreia</span>
              </p>
            )}
          />
          <ConditionalRenderer
            condition={altura}
            renderOnSuccess={() => (
              <p>
                {altura}
                <span>Altura</span>
              </p>
            )}
          />
          <ConditionalRenderer
            condition={numeroDeGols > 0}
            renderOnSuccess={() => (
              <p>
                {numeroDeGols}
                <span>Número de gols</span>
              </p>
            )}
          />
          <ConditionalRenderer
            condition={numeroDePartidas > 0}
            renderOnSuccess={() => (
              <p>
                {numeroDePartidas}
                <span>Número de partidas</span>
              </p>
            )}
          />
          <ConditionalRenderer
            condition={camiseta}
            renderOnSuccess={() => (
              <ExternalLink to={camiseta}>Compre esta camiseta</ExternalLink>
            )}
          />
        </CardBody>
      </CardContent>
    </Card>
  );
}
