import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const Ul = styled.ul`
  height: ${(props) => (props.open ? 'auto' : '0')};
  position: absolute;
  overflow: hidden;
  z-index: 10;
  background: white;
  margin: 0;
  padding: 0 1rem;
  list-style: none;
  padding: 0;
  box-shadow: 0 5px 1px 0 rgba(60, 64, 67, 0.08);
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.color.accent_dark} 0%, ${theme.color.black} 100%)`};
  color: ${(props) => props.theme.color.text_on_primary};
  li {
    margin: 0;
    a {
      padding: 0.3rem 1rem;
      width: 100%;
      display: block;
    }
    &:hover {
      color: ${(props) => props.theme.color.accent};
    }
  }
`;

const Div = styled.div`
  position: relative;
  margin: 1rem 0;
`;

const Button = styled.button`
  background: ${(props) => props.theme.color.accent_dark};
  color: ${(props) => props.theme.color.text_on_primary};
  border: none;
  padding: 0 1rem;
  svg {
    margin-left: 1rem;
  }
`;

const LinkDiv = ({ list }) => {
  const [open, setOpen] = useState(false);
  return (
    <Div>
      <Button
        type="button"
        onClick={() => setOpen(!open)}
        aria-label="Abrir link para outros elencos"
        aria-pressed={open}
        aria-expanded={open}
      >
        Elencos
        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
      </Button>
      <Ul open={open}>
        {list.map((item) => (
          <li>
            <Link to={item.slug}>{item.title}</Link>
          </li>
        ))}
      </Ul>
    </Div>
  );
};

LinkDiv.propTypes = {
  list: PropTypes.arrayOf([
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ]),
};

export default LinkDiv;
