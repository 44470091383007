import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { parseData } from '../helpers/news';

export default function withCouncilNews(Component) {
  const query = graphql`
    query GET_NEWS {
      wpgraphql {
        posts(
          where: {
            orderby: { field: DATE, order: DESC }
            categoryName: "conselho-deliberativo"
          }
          first: 20
        ) {
          nodes {
            id
            title
            excerpt
            date
            slug
            featuredImage {
              node {
                sourceUrl
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            postData {
              highlight
              redirectLink
              exclusive
            }
          }
        }
      }
    }
  `;

  const render = (data) => {
    const {
      wpgraphql: {
        posts: { nodes: news },
      },
    } = data;
    const councilNews = parseData(news);
    return <Component news={councilNews} />;
  };

  return <StaticQuery query={query} render={render} />;
}
