/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { News } from './news';
import { RenderNewsCard } from './cards';

const Container = styled.div`
  width: 100%;
  /* padding: 1rem; */

  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    height: 100px;
    width: 100px;
    font-weight: 700;
    transform: translateY(-50%);
    margin-top: 0;
    background: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${(props) => props.theme.dimensions.mobile.med}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
`;

const Li = styled.li`
  max-width: 405px;
  padding: 1rem;
  box-sizing: border-box;
  a {
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
      0 1px 3px 1px rgba(60, 64, 67, 0.16);
  }
`;

function NewsCarousel(props) {
  const { news } = props;
  const params = {
    slidesPerView: 'auto',
    // spaceBetween: 30,
    loop: true,
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  return (
    <Container>
      <News>
        <Swiper {...params}>
          {news.map((item) => (
            <Li key={item.id}>
              <RenderNewsCard item={item} />
            </Li>
          ))}
        </Swiper>
      </News>
    </Container>
  );
}

NewsCarousel.propTypes = {
  players: PropTypes.arrayOf(PropTypes.any),
};

export default NewsCarousel;
